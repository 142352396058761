<!-- 个人信息 -->
<template>
  <div>
    <div style="height: 3rem"></div>
    <div class="box_css">
      <!-- 个人信息 -->
      <div v-if="isPerson">
        <div class="title_css">个人信息</div>
        <div class="form_css">
          <avatar
            :imagesrc="form.photo"
            style="margin-bottom: 5px;"
            fontSize="10"
            BRnumber="50"
          />
          <div class="name_css">{{ form.userName }}</div>
        </div>
      </div>
      <div v-else>
        <div class="title_css">轻触以切换账号</div>
        <div class="form_css" v-if="userList.length>0">
          <div
            class="userItemBox"
            v-for="(item, index) in userList"
            :key="item.UserID"
            @click="chooseUser(item)"
          >
            <avatar
              style="margin-left: 5px;"
              :imagesrc="item.photo"
              fontSize="3"
              BRnumber="50"
            />
            <div class="userName_css">{{ item.userName }}</div>
            <div class="loginAccount_css">({{ item.loginAccount }})</div>
            <!-- 当前账号 -->
              <img class="nowAccount" v-if="item.UserID == nowUserId" src="@/assets/now.png"></img>
          </div>
        </div>
         <div class="noUser" v-else>暂无缓存用户</div>  
      </div>
      <div  v-if="isPerson" class="clearCacheBox">
            <el-checkbox size="medium" v-model="clearCache"
              >清除此用户缓存</el-checkbox
            >
          </div>
      <div v-if="isPerson" class="LogOut_css" @click="LogOut()">退出登录</div>

      <div class="change_css" @click="ChangeAccount()">{{ isPerson?'切换账号':'个人信息' }}</div>
    </div>
  </div>
</template>
<script>
import { GetClassUserList, Login } from '@/api/index.js'
export default {
  data() {
    return {
      isPerson: true, //个人信息还是切换账号
      nowUserId: -1, //当前登录账号的id
      // radios: 1,
      // classID: localStorage.getItem('classID'),
      // list:[],
      form: JSON.parse(localStorage.getItem('userInformation')),
      userList: [], //缓存的用户信息
      clearCache:false, //是否清除用户缓存
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    // 退出登录
    LogOut() {
      if(this.clearCache){
        let index= this.userList.findIndex(
                  (Item) => Item.UserID == this.nowUserId
                )
                if(index!=-1){
                  this.userList.splice(index,1)
                  localStorage.setItem(
                    'userList',
                    JSON.stringify( this.userList)
                  )
                }
      }
      localStorage.removeItem('userInformation')
      localStorage.removeItem('smartCampusToken')
      this.$bus.$emit('getItem')
      this.$router.push({ path: '/scanCode' })
    },
    //获取用户缓存
    getUserList() {
      this.userList = JSON.parse(localStorage.getItem('userList')) || []
      this.nowUserId =
        JSON.parse(localStorage.getItem('userInformation')).UserID || -1

      console.log('打印获取的用户信息', this.userList, this.nowUserId)
    },
    //切换账号
    ChangeAccount() {
      console.log('点击了切换账号')
      this.isPerson = !this.isPerson
      
    },
    //切换用户
    chooseUser(obj){
      if(this.nowUserId==obj.UserID){
        return
      }
      this.nowUserId=obj.UserID
      Login({
        loginAccount:obj.loginAccount,
        loginPwd: obj.loginPwd,
      }).then((res)=>{
        console.log('登录成功', res)
          if (res.data.GathererID == 3) {
            localStorage.setItem('smartCampusToken', res.data.Token)
            localStorage.setItem('userInformation', JSON.stringify(res.data))
            this.$bus.$emit('getItem')
            this.form=JSON.parse(localStorage.getItem('userInformation'))
            this.$message.success('切换账号成功')
            setTimeout(()=>{
              this.$router.push({ path: '/' })
            },300)
           
           
          }else{
            this.$message.warning('请教师账号登录')
          }
      }).catch((err) => {
        this.$message.warning('登录失败')
        })
    }
  },
}
</script>
<style scoped>
.box_css {
  width: 1200px;
  height: calc(100vh - 230px);
  margin: 0 auto;
  background-color: #ffffffda;
  border-radius: 25px;
}
.title_css {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding: 40px 0;
}
.form_css {
  padding: 0 120px 0 130px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 290px;
  overflow-y: auto;

  /* border: 1px solid red; */
}
.loginAccount_css {
  font-size: 16px;
  color: #909399;
  margin-left: 10px;
}
.clearCacheBox{
  margin: 0 auto;
  text-align: center;
}
.noUser{
  margin: 0 auto;
  height: 300px;
  width: 500px;
  color: #909399;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nowAccount{
  width:50px ;
  height: 50px;
  position: absolute;
  top: 0;
  right:0px ;
}
.userItemBox {
  position: relative;
  width: 500px;
  min-height: 60px;
  height: auto;
  padding: 0 5px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.in_box_css {
  display: flex;
  align-items: center;
}
.name_css {
  font-size: 28px;
  margin-left: 15px;
}
.userName_css {
  font-size: 25px;
  margin-left: 15px;
  letter-spacing: 2px;
}
.change_css {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 0;
  height: 60px;
  width: 450px;
  background-color: #409eff;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
  cursor: pointer;
}
.LogOut_css {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 0;
  height: 60px;
  width: 450px;
  background-color: #ec2929;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
  cursor: pointer;
}
</style>
